export default function calculateIsOverConsentQuota({ consentsCount, provisions }) {
  const quota = getConsentQuota(provisions)

  // eslint-disable-next-line eqeqeq
  if ( quota == null || consentsCount == null ) {
    console.warn('Consent quota information missing. Treating as if consent quota not exceeded.')
    return false
  }

  return ( consentsCount > quota )
}

function getConsentQuota(provisions) {
  if ( !provisions ) {
    return
  }

  const consentQuotaProvision = provisions
    .find(({ key }) => key === 'consent_quota')

  const count = consentQuotaProvision?.config?.operation_count

  return ( count < 0 ) ? Infinity : count
}
