import filterConsents from '../filterConsents'

import convertTermlyConsentsToGTM from './convertTermlyConsentsToGTM'
import makeUserPrefUpdateEvent from './makeUserPrefUpdateEvent'

const GOOGLE_TAG_DEVELOPER_ID = 'dNzg2MD'

if ( !window.dataLayer ) {
  window.dataLayer = []
}

/**
 * @name GTM
 */
export default {
  initCMP(defaults) {
    this.gtag('send', `developer_id.${ GOOGLE_TAG_DEVELOPER_ID }`, true)
  },

  // TODO: It's entirely unclear whether we need both gtag() and push() here,
  // but since my current task does not involve communicating with our
  // GTM template, I'm going to leave push() in place.

  /**
   * Pushes the supplied arguments into the data layer as an array
   *
   * @param {...*} args
   *
   * @see https://developers.google.com/tag-platform/devguides/gtag-integration#create-integration
   */
  gtag() {
    if ( window.TERMLY_FORCE_DISABLE_GCM ) {
      return
    }

    // N.B.: we can't use "rest arguments" (...args) here, because the
    // Google gtag code refuses to process what we push in. This despite
    // the fact that `arguments` is a deprecated language feature.
    window.dataLayer.push(arguments)
  },

  /**
   * Pushes the supplied object into the dataLayer
   *
   * @param {Object} config
   */
  push(config) {
    if ( window.TERMLY_FORCE_DISABLE_GCM ) {
      return
    }

    window.dataLayer.push(config)
  },

  /**
   * Sends the consents to GTM.
   *
   * @param {Object.<String, Boolean>} consents
   */
  send(consents) {
    const filteredConsents = filterConsents(consents)

    this.push(makeUserPrefUpdateEvent(filteredConsents))

    this.gtag('consent', 'update', convertTermlyConsentsToGTM(filteredConsents))

    this.push({
      event: 'Termly.consentSaveDone',
    })
  },

  /**
   * Note: No conversion from Termly to GCM consents is performed.
   *
   * @param {Object <String, (Boolean|String)>} gcmConsents
   */
  sendConsentDefault(gcmConsents) {
    this.gtag('consent', 'default', gcmConsents)
  },
}
