import Termly from 'termly-namespace'

import {
  getApiBase,
  getWebsiteUuid,
} from './state'


const website = () => {
  const apiBase = getApiBase()

  if ( !apiBase ) {
    throw new Error('URL.website() called before the API base was initialized in state')
  }

  const websiteUuid = getWebsiteUuid()

  if ( !websiteUuid ) {
    throw new Error('URL.website() called before the website UUID was initialized in state')
  }

  return `${ apiBase }/api/v1/snippets/websites/${ websiteUuid }`
}

const statistic = () => `${ website() }/statistics`

const getCountry = () => {
  const apiBase = getApiBase()

  return `${ apiBase }/api/v1/ip`
}

const getCookies = ({ region }) => {
  const baseURL = `${ website() }/cookies`

  if ( Termly.tcf && region === 'eu' ) {
    return `${ baseURL }?include_tcf_cookies=false`
  }

  return baseURL
}

const saveCookieConsent = () => `${ website() }/cookie_consents`

const websiteProvisions = () => {
  const apiBase = getApiBase()

  const websiteUUID = getWebsiteUuid()

  if ( !websiteUUID ) {
    throw new Error('URL.website() called before the website UUID was initialized in state')
  }

  return `${ apiBase }/api/v1/websites/${ websiteUUID }/provisions`
}

const docLink = (name, uuid) => {
  if ( !name ) {
    return ''
  }

  const apiBase = getApiBase()

  return `${ apiBase }/policy-viewer/policy.html?policyUUID=${ uuid }`
}

const fullTcfGVL = () => {
  const apiBase = getApiBase()

  return `${ apiBase }/resource-blocker/support/complete-tcf-gvl`
}

const tcfGVL = ({ lang, websiteUUID }) => {
  const apiBase = getApiBase()

  return `${ apiBase }/resource-blocker/support/${ websiteUUID }/tcf-gvl?lang=${ lang }`
}

const gacmVendors = ({ websiteUUID }) => {
  const apiBase = getApiBase()

  return `${ apiBase }/resource-blocker/support/${ websiteUUID }/gacm-vendors`
}

export default {
  docLink,
  fullTcfGVL,
  gacmVendors,
  getCookies,
  getCountry,
  saveCookieConsent,
  statistic,
  tcfGVL,
  website,
  websiteProvisions,
}
