import Categories, {
  ALLOW_ALL_CONSENT,
} from 'constants/categories'


//
// TODO: test this.
//
export default function triggerGetUpdatedCookieWhitelistByTermly(cookies, visitorId, cookieConsent) {
  if ( !window.getUpdatedCookieWhitelistByTermly ) {
    return
  }

  const categories = getCategories(cookieConsent)
  const cookieWhitelist = getWhitelist(cookieConsent, cookies)

  window.getUpdatedCookieWhitelistByTermly({
    categories,
    cookies: cookieWhitelist,
    uuid: visitorId,
  })
}

function getCategories(cookieConsent) {
  const consent = cookieConsent || ALLOW_ALL_CONSENT

  return Object.entries(consent)
    .filter(([category, isConsented]) => Categories.has(category))
    .filter(([category]) => consent[category])
    .map(([category]) => category)
}

function getWhitelist(categories, cookies) {
  const whitelist = categories
    ? Object.entries(cookies)
      .filter(([key]) => categories[key])
      .map(([key, value]) => value).reduce((acc, val) => acc.concat(val), [])
    : cookies.essential

  if ( !whitelist ) {
    return []
  }

  return whitelist.map(({ name, domain, tracker_type }) => ({ name, provider: domain, type: tracker_type }))
}
