import Termly from 'termly-namespace'

import CookieConsents from './cookieConsents'


if ( window.TERMLY_RESOURCE_BLOCKER_LOADED ) {
  throw new Error('Termly resource blocker has already been loaded on this page. ' +
                  `Check your markup for duplicate <script> tags including ${ document.currentScript.src }`)
}

window.TERMLY_RESOURCE_BLOCKER_LOADED = true

if ( !window.Termly ) {
  window.Termly = {}
}

window.Termly.getConsentState = () => {
  return CookieConsents.getConsentSettings() || Termly.getDefaultConsents()
}

if ( !window.getUpdatedCookieWhitelistByTermly ) {
  window.getUpdatedCookieWhitelistByTermly = function(data) {
  }
}

if ( !window.getCookieWhitelistByTermly ) {
  window.getCookieWhitelistByTermly = function(data) {
  }
}
